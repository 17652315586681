<template>
  <div class="box-container">
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        cols="12"
        xl="8"
        lg="12"
      >
        <div class="d-flex justify-space-between flex-wrap mb-5">
          <div class="d-flex align-center">
            <v-avatar
              tile
              size="25"
              class="me-3"
            >
              <img
                src="@/assets/images/icons/credit-card_filled.svg"
                alt=""
              >
            </v-avatar>
            <h2 class="mb-0">
              {{ $t("Gestion des Compte en Banque") }}
            </h2>
          </div>
        </div>
        <v-row>
          <v-col cols="12">
            <v-alert
              type="info"
              dismissible
            >
              <p>
                {{
                  $t(
                    "Les informations de votre carte sont sauvegarder chez notre partenaire mongopay pour plus de sécurité"
                  )
                }}
              </p>
            </v-alert>
          </v-col>
          <v-col cols="12">
            <v-card :loading="loading">
              <v-card-title>
                {{ $t("Comptes Banque") }}
                <v-spacer />
                <v-dialog
                  v-model="dialog"
                  max-width="600px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      color="primary"
                      v-on="on"
                    >
                      {{ $t("Nouveau compte bancaire") }}
                    </v-btn>
                  </template>
                  <base-card>
                    <v-card-title>
                      {{ $t("Nouveau compte bancaire") }}
                      <v-spacer />
                      <v-btn
                        class="mt-4"
                        absolute
                        right
                        icon
                        color="secondary"
                        text
                        @click="dialog = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <bank-account-form @saved="saved" />
                    </v-card-text>
                  </base-card>
                </v-dialog>
              </v-card-title>
              <v-divider />

              <v-simple-table>
                <thead>
                  <tr>
                    <th>{{ $t("Mp bank id") }}</th>
                    <th>{{ $t("Mp user id") }}</th>
                    <th>{{ $t("Status") }}</th>
                    <th>{{ $t("Créé le") }}</th>
                    <th>{{ $t("Action") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="bk in items">
                    <td>{{ bk.mp_bank_id }}</td>
                    <td>{{ bk.mp_user_id }}</td>
                    <td>{{ bk.status }}</td>
                    <td>{{ bk.created_at }}</td>
                    <td />
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="snackBarColor"
    >
      {{ snackBarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="isSnackbarBottomVisible = false"
        >
          {{ $t("Fermer") }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import DashbordSidebar from '@/components/DashboardSidebar'
  import { mapState } from 'vuex'

  import { formatDate } from '@/core/utils/filter'
  import ProfileMenu from '../../components/ProfileMenu.vue'
  import PhotoWidget from './PhotoWidget.vue'
  import BankAccountForm from './BankAccountForm.vue'

  export default {
    components: {
      ProfileMenu,
      DashbordSidebar,
      PhotoWidget,
      BankAccountForm,
    },
    data () {
      return {
        isSidebar: false,
        loading: false,
        dialog: false,
        isSnackbarBottomVisible: false,
        snackBarMessage: '',
        snackBarColor: 'success',
        payout: {
          bank_account_id: null,
        },
        bankaccounts: [],
        transactions: [],
        kycvalidated: true,
        wallet: {
          amount: 0,
        },
        bank_account: {},
        bank_types: [],

        callback: null,
        preview: null,

        mp: {},
        termsRules: [v => !!v || i18n.t('Accepter les conditions générales')],
        descriptionRules: [v => !!v || i18n.t('Le nom est requis')],
        nameRules: [v => !!v || i18n.t('Le nom est requis')],
        countryRules: [v => !!v || i18n.t('Le Pays est requis')],
        cityRules: [v => !!v || i18n.t('La ville est requis')],
        firstNameRules: [v => !!v || i18n.t('Le prénom est requis')],
        lastNameRules: [v => !!v || i18n.t('Le nom de famille est requis')],
        emailRules: [v => !!v || i18n.t('L\'e-mail est obligatoire')],
        passwordRules: [v => !!v || i18n.t('Le mot de passe est requis')],
        telRules: [v => !!v || i18n.t('Le numéro de téléphone est requis.')],
        addressRules: [v => !!v || i18n.t('L\'adresse 1 est requise.')],
        dateRules: [v => !!v || i18n.t('La date de creation est requise.')],
        items: [],
      }
    },
    computed: {
      ...mapState({
        user: 'user',
        login: 'login',
        displayNotification: 'display_notification',
        countries: 'countries',
      }),
      scrollHeight () {
        return this.windowHeight - 48 + 'px'
      },
    },
    created () {
      this.getAccounts()
    },
    methods: {
      saved () {
        this.dialog = false
        this.getAccounts()
      },
      getAccounts () {
        this.loading = true
        // eslint-disable-next-line no-undef
        axios
          .get('/bank-accounts')
          .then((response) => {
            this.loading = false
            if (response.data) {
              this.items = response.data
            }
          })
          .then(() => {})
      },
      save () {
        console.log(this.payout)
        if (!this.payout.amount) {
          this.toast(this.$t('Le montant est requis'), 'red')
          return
        }

        // console.log(withdrawal, amountavailable)
        if (this.payout.amount > this.wallet.amount) {
          this.toast(this.$t('Alert montant insufisant'), 'red')
          return
        }

        if (
          !confirm(
            this.$t('Vous confirmez le retrait de ') + this.payout.amount + '€',
          )
        ) {
          return
        }

        this.loading = true
        axios
          .post('/payout', this.payout)
          .then((response) => {
            this.loading = false
            console.log(response)
            this.toast(this.$t('transaction initiée'), 'success')

            this.getWallet()
          // window.location.href = '{{route("payout.wallet_detail", $user->wallet->id)}}';
          //
          })
          .catch((error) => {
            console.log(error)
            this.loading = false
          })
      },

      toast (message, color) {
        this.isSnackbarBottomVisible = true
        this.snackBarColor = color
        this.snackBarMessage = message
      },
    },
  }
</script>
