<template>
  <div class="pa-5">
    <v-form
      ref="registerForm"
      v-model="valid"
      lazy-validation
      @submit.prevent="onSubmit"
    >
      <v-row>
        <v-col cols="12">
          <v-alert
            dismissible
            type="info"
            outlined
            text
          >
            {{
              $t(
                "There is a KYC limit on Pay-Outs in order to fight fraud, money laundering and financing of terrorism. You have to send some documents through the API. It's compulsory for any payout amount."
              )
            }}
          </v-alert>
        </v-col>
        <v-col cols="12" />
        <v-col
          cols="12"
          class="py-0"
        >
          <p class="text-14 mb-1">
            {{ $t("Type de compte") }}
          </p>
          <v-select
            v-model="bank_account.bank_type"
            item-value="key"
            item-text="label"
            :items="bank_types"
            required
            placeholder=""
            outlined
            dense
          />
        </v-col>

        <v-col
          cols="12"
          class="py-0"
        >
          <p class="text-14 mb-1">
            {{ $t("Owner name") }}
          </p>
          <v-text-field
            v-model="bank_account.owner_name"
            required
            placeholder=""
            outlined
            dense
          />
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <p class="text-14 mb-1">
            {{ $t("Address 1 - The first line of the address") }}
          </p>
          <v-text-field
            v-model="bank_account.address_address1"
            :rules="addressRules"
            placeholder=""
            outlined
            dense
            required
          />
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <p class="text-14 mb-1">
            {{ $t("Address 2 - The second line of the address") }}
          </p>
          <v-text-field
            v-model="bank_account.address_address2"
            placeholder=""
            outlined
            dense
          />
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <p class="text-14 mb-1">
            {{ $t("Postalcode - The postal code of the address - can be alphanumeric, dashes or spaces") }}<!-- <span class="red--text">*</span> -->
          </p>
          <v-text-field
            v-model="bank_account.address_postalcode"
            placeholder=""
            outlined
            dense
          />
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <p class="text-14 mb-1">
            {{ $t("Region - The region of the address - this is optional except if the Country is US, CA or MX") }}
          </p>
          <v-text-field
            v-model="bank_account.address_region"
            placeholder=""
            outlined
            dense
          />
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <p class="text-14 mb-1">
            {{ $t("Bank account country") }}
          </p>
          <v-autocomplete
            v-model="bank_account.address_country"
            dense
            :rules="countryRules"
            required
            :items="countries"
            outlined
            item-value="code"
            item-text="name"
          />
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <p class="text-14 mb-1">
            {{ $t("City - The city of the address") }}
          </p>
          <v-text-field
            v-model="bank_account.address_city"
            :rules="cityRules"
            required
            placeholder=""
            outlined
            dense
          />
        </v-col>
        <v-col
          v-if="bank_account.bank_type === 'iban'"
          cols="12"
        >
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("IBAN") }}
              </p>
              <v-text-field
                v-model="bank_account.iban"
                :rules="ibanRules"
                required
                placeholder=""
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"

              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("BIC") }}
              </p>
              <v-text-field
                v-model="bank_account.bic"
                :rules="bicRules"
                required
                placeholder=""
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col
          v-if="bank_account.bank_type === 'us'"
          cols="12"
        >
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Account Number") }}
              </p>
              <v-text-field
                v-model="bank_account.account_number"
                :rules="account_numberRules"
                required
                placeholder=""
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("ABA") }}
              </p>
              <v-text-field
                v-model="bank_account.aba"
                required
                placeholder=""
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Deposite account type") }}
              </p>
              <v-text-field
                v-model="bank_account.deposit_account_type"
                required
                placeholder=""
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="bank_account.bank_type === 'ca'"
          cols="12"
        >
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Brance Code") }}
              </p>
              <v-text-field
                v-model="bank_account.branch_code"
                :rules="branch_codeRules"
                required
                placeholder=""
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"

              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Institution Number") }}
              </p>
              <v-text-field
                v-model="bank_account.institution_number"
                :rules="institution_numberRules"
                required
                placeholder=""
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"

              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Account Number") }}
              </p>
              <v-text-field
                v-model="bank_account.account_number"
                :rules="account_numberRules"
                required
                placeholder=""
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"

              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Bank Name") }}
              </p>
              <v-text-field
                v-model="bank_account.bank_name"
                required
                placeholder=""
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col
          v-if="bank_account.bank_type === 'gb'"
          cols="12"
        >
          <v-row>
            <v-col
              cols="12"

              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Sort Code") }}
              </p>
              <v-text-field
                v-model="bank_account.sort_code"
                :rules="sort_codeRules"
                required
                placeholder=""
                outlined
                dense
              />
            </v-col>

            <v-col
              cols="12"

              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Account Number") }}
              </p>
              <v-text-field
                v-model="bank_account.account_number"
                :rules="account_numberRules"
                required
                placeholder=""
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col
          v-if="bank_account.bank_type === 'other'"
          cols="12"
        >
          <v-row>
            <v-col
              cols="12"

              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("BIC") }}
              </p>
              <v-text-field
                v-model="bank_account.bic"
                :rules="bicRules"
                required
                placeholder=""
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"

              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Account Number") }}
              </p>
              <v-text-field
                v-model="bank_account.account_number"
                :rules="account_numberRules"
                required
                placeholder=""
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          lg="6"
        >
          <div class="mb-4 ">
            <v-btn
              :disabled="!valid"
              color="primary"
              class="text-capitalize font-600"
              type="submit"
              :loading="loading"
            >
              {{ $t("Enregistrez") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="snackBarColor"
    >
      {{ snackBarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn

          text
          v-bind="attrs"
          @click="isSnackbarBottomVisible = false"
        >
          {{ $t("Fermer") }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import { i18n } from '@/plugins/i18n'
  import { mapActions, mapGetters, mapState } from 'vuex'

  import FileUpload from '../../components/file_upload.vue'
  import PhotoWidget from './PhotoWidget.vue'

  export default {
    components: { FileUpload, PhotoWidget },

    data: vm => {
      return {
        dateMenu: false,
        activePicker: null,
        valid: false,
        checkbox: false,
        loading: false,
        isPasswordVisible: false,
        name: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',

        errorMessages: { name: 'Est requis' },
        salutationRules: [v => !!v || i18n.t('Requis.')],
        termsRules: [v => !!v || i18n.t('Accepter les conditions générales')],
        descriptionRules: [v => !!v || i18n.t('Le nom est requis')],
        nameRules: [v => !!v || i18n.t('Le nom est requis')],
        countryRules: [v => !!v || i18n.t('Le Pays est requis')],
        cityRules: [v => !!v || i18n.t('La ville est requis')],
        firstNameRules: [v => !!v || i18n.t('Le prénom est requis')],
        lastNameRules: [v => !!v || i18n.t('Le nom de famille est requis')],
        emailRules: [v => !!v || i18n.t('L\'e-mail est obligatoire')],
        passwordRules: [v => !!v || i18n.t('Le mot de passe est requis')],
        telRules: [v => !!v || i18n.t('Le numéro de téléphone est requis.')],
        addressRules: [v => !!v || i18n.t('L\'adresse 1 est requise.')],
        dateRules: [v => !!v || i18n.t('La date de creation est requise.')],
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
        user: {
          dob: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        },

        bank_account: {},
        bank_types: [],

        callback: null,
        preview: null,

        mp: {},
      }
    },
    computed: {
      ...mapGetters(['getCartProducts', 'unreadNotifications']),
      ...mapState({
        userData: 'user',
        salutations: 'salutations',
        countries: 'countries',
        displayNotification: 'display_notification',
      }),
      computedDateFormatted () {
        return this.formatDate(this.user.user.dob || this.date)
      },
      date () {
        return this.user.dob
      },
    },
    watch: {
      userData (val) {
        this.user = val
      },
      date (val) {
        this.dateFormatted = this.formatDate(this.user.user.dob || this.date)
      },
      dateMenu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
    },
    created () {
      this.getAccounts()
      this.user = this.$store.state.user
    },
    methods: {
      ...mapActions(['pushItem']),
      save (date) {
        this.$refs.dateMenu.save(date)
      },
      getAccounts () {
        this.loading = true
        // eslint-disable-next-line no-undef
        axios
          .get('/bank-account-form/0')
          .then((response) => {
            this.loading = false
            if (response.data) {
              this.bank_account = response.data.BankAccount
              this.bank_types = response.data.bank_types
            }
          })
          .then(() => {})
      },

      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      onSubmit () {
        const isFormValid = this.$refs.registerForm.validate()

        if (!isFormValid) return
        this.loading = true
        // eslint-disable-next-line no-undef
        axios({
          method: 'post',
          url: '/bank-accounts',
          data: this.bank_account,
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        }).catch(error => {
          // this.loading = false
          this.loading = false
          console.log(error)
          // eslint-disable-next-line consistent-return
        }).then((response) => {
          if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              this.errorMessages = { ...response.data.errors }
            } else {
              this.toast(response.data.message, 'red')

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return false
          }
          this.$emit('saved')
          this.toast(this.$t('Informations enregistrées avec succès'), 'success')
        })
          .then(() => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            // TODO: Next Update - Show notification
            console.error('Oops, Unable to Register!')
            console.log('error :>> ', error.response || '')
            // this.errorMessages = error.response.data.error
          })
      },
      toast (message, color) {
        this.isSnackbarBottomVisible = true
        this.snackBarColor = color
        this.snackBarMessage = message
      },
    },
  }
</script>

<style lang="scss" scoped>
  .sign-up-card {
    width: 500px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    margin: 2rem auto;
    box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
    @media(max-width: 500px){
      width: 100%;
    }
    .sign-up-card-container {
      padding: 3rem 3.75rem 0px;
      @media(max-width: 500px){
        padding: 3rem 1rem 0px;
      }
    }
  }
  .box-sidebar {
    .sidebar-slider {
      .v-messages {
        display: none;
      }
    }
  }
</style>
